<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <!-- <b-overlay
      :show="showLoading"
      rounded="sm"
    > -->
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('Name')"
                label-for="register-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="name"
                    name="register-name"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('Detail')"
                label-for="register-detail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Detail"
                  vid="detail"
                  rules="required"
                >
                  <b-form-input
                    id="register-detail"
                    v-model="detail"
                    name="register-detail"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Detail')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Province')"
                label-for="register-province"
              >

                <v-select
                  id="register-province"
                  v-model="provinceId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="code"
                  :options="respDataProvince"
                  :reduce="(respDataProvince) => respDataProvince"
                  class="select-size-lg"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >

                    {{ showFromCurrentLanguage(option.name) }}
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    {{ showFromCurrentLanguage(option.name) }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('District')"
                label-for="register-district"
              >

                <v-select
                  id="register-district"
                  v-model="districtId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="code"
                  :options="respDataDistrict"
                  :reduce="(respDataDistrict) => respDataDistrict"
                  class="select-size-lg"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >

                    {{ showFromCurrentLanguage(option.name) }}
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    {{ showFromCurrentLanguage(option.name) }}
                  </template>
                </v-select>

              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Subdistrict')"
                label-for="register-sub-district"
              >

                <v-select
                  id="register-sub-district"
                  v-model="subDistrictId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="code"
                  :options="respDataSubDistrict"
                  :reduce="(respDataSubDistrict) => respDataSubDistrict"
                  class="select-size-lg"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >

                    {{ showFromCurrentLanguage(option.name) }}
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    {{ showFromCurrentLanguage(option.name) }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
    <!-- </b-overlay> -->
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BButton,
  // BInputGroupAppend,
  // BInputGroup,
  BFormInput, BForm, BFormGroup, BRow, BCol,
  BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    // BInputGroupAppend,
    // BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BOverlay,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      name: '',
      detail: '',
      provinceId: null,
      districtId: null,
      subDistrictId: null,
      showLoading: false,
      required,
    }
  },
  computed: {
    respDataProvince() {
      return store.state[this.storeModuleName].respDataProvince != null ? store.state[this.storeModuleName].respDataProvince.data : []
    },
    respDataDistrict() {
      return store.state[this.storeModuleName].respDataDistrict != null ? store.state[this.storeModuleName].respDataDistrict.data : []
    },
    respDataSubDistrict() {
      return store.state[this.storeModuleName].respDataSubDistrict != null ? store.state[this.storeModuleName].respDataSubDistrict.data : []
    },
  },
  watch: {
    provinceId(val) {
      this.districtId = null
      this.subDistrictId = null
      this.getDistrict(val)
    },
    districtId(val) {
      this.subDistrictId = null
      this.getSubDistrict(val)
    },
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          console.log(this.data)
          const {
            name, detail, district, province, subDistrict, _id,
          } = this.data
          this.dataId = _id
          this.name = name
          this.detail = detail
          if (province.code !== null) { this.provinceId = province }
          if (district.code !== null) {
            setTimeout(() => {
              this.districtId = district
            }, 1000)
          }
          if (subDistrict !== null) {
            setTimeout(() => {
              this.subDistrictId = subDistrict
            }, 1000)
          }
        }
      }
    },
  },
  created() {
    this.showLoading = true
    store
      .dispatch(`${this.storeModuleName}/getProvince`)
    // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.showLoading = false
      }).catch(error => {
        this.showLoading = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  methods: {
    getDistrict(val) {
      if (val == null) {
        return
      }
      this.showLoading = true
      store
        .dispatch(`${this.storeModuleName}/getDistrict`, val.code)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.showLoading = false
        }).catch(error => {
          this.showLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    getSubDistrict(val) {
      if (val == null) {
        return
      }
      this.showLoading = true
      store
        .dispatch(`${this.storeModuleName}/getSubDistrict`, val.code)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.showLoading = false
        }).catch(error => {
          this.showLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    initValues() {
      this.dataId = null
      this.name = ''
      this.detail = ''
      this.provinceId = null
      this.districtId = null
      this.subDistrictId = null
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            name: this.name,
            detail: this.detail,
            province: this.provinceId,
            district: this.districtId,
            sub_district: this.subDistrictId,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.userId
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
